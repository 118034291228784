/* Global CSS */
* {
  outline: red;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
:root {
  --primary: #4594ee;
  --heading-color: #2b2d46;
  --bg-shade: #f5fcff;
  --github: #a44569;
  --darkblue: #2d2e6f;
  --black: #000000;
  --white: #ffffff;
}
.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
  height: 25px;
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}
.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}
.btn-github {
  color: var(--white);
  background-color: var(--github);

  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}

.btn-github:hover path {
  stroke: var(--github);
  fill: var(--github);
}

.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}
/* Global CSS Ends */
/* Main Headings */

/* Heading 1 */
h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/* Heading 2 */
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/* Heading 3 */
h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  margin-top: 10px;
  padding: 5px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: #f3f3f3;
}
/* Body 3 */
.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Section Titles */
/* Skills & About Me */
.section--title {
  color: var(--heading-color);
  font-size: 80px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
  padding-bottom: 16px;
  letter-spacing: 1px;
}
/* Portfolio, Testimonial & Contact Me */
.sub--title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
/* Section Titles Ends */

/* Navbar Style Start */
.navbar {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  background: var(--white);
  box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 1000;
}

.svg-icon {
  width: 65px;
  height: 65px;
  fill: var(--black);
  overflow: hidden;
  vertical-align: middle;
  margin: 0 0 3px 0;
}
.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.navbar--items ul > li > a {
  text-decoration: none;
}

.navbar-photo {
  padding: 15px;
}
/* Navbar Content */
.navbar--content {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}
.navbar--active-content {
  color: var(--primary);
}

.logo {
  height: 30px;
  padding: 0;
  margin: 0 0 0 20px;
}

.cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.header--social--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  margin: 5px 0 0 0 !important;
}

.header--social--icon > a:hover {
  color: var(--primary);
  transform: translatey(-4px);
  transition: transform 0.2s ease-in-out;
  filter: drop-shadow(0 0 0.75rem (--primary));
}

.header--social--icon > li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
  padding: 0;
  margin: 0 0 0 10px !important;
}
/* Navbar Styler Ends */

/* Hero Section Style */
.hero--section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--bg-shade);
  width: 100%;
  z-index: 1;
  position: relative;
}

.hero--wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 95%;
  height: auto;
  align-items: center;
}
.hero--section--content--box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
}
.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 85px;
}
.hero--section--content--box > button {
  margin-top: 21.333px;
}
.hero--section--title {
  color: var(--heading-color);
  font-size: 70px;
  font-weight: 700;
  line-height: 90px;
  max-width: 100vw;
}
.hero--section--title--color {
  color: var(--primary);
}
.hero--section--description {
  color: var(--heading-color);
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 24px;
  max-width: 800px;
}

.hero--section--img {
  display: flex;
  max-width: 500px;
  max-height: 500px;
}
.hero--section--img > img {
  max-width: 500px;
  max-height: 500px;
  border-radius: 50%;
}

.blinking-cursor:after {
  content: "|";
  color: #000;
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.GIT {
  margin-left: 85px;
}
/* Hero Section Style Ends */

/* Skills Section Style */
.skills--section {
  display: flex;
  padding: 40px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  overflow-x: hidden;
}
.skills--section--heading {
  color: var(--heading-color);
  font-size: 64px;
  font-weight: 700;
  line-height: 77px;
  text-align: left;
}
.skills--section--container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  gap: 32px;
}
.skills--section--card {
  display: inline-block;

  padding: 16px;
  align-items: flex-start;
  flex: 4 0 0;
  border-radius: 10px;
  background: var(--bg-shade);
  width: 100%;
  max-width: 400px;
  min-width: 40vw;
}
s .skills--section--card:hover {
  border-bottom: 4px solid var(--primary);
}
.skills--section--card:hover .skills--section--description {
  color: var(--darkblue);
}
.skills--section--img {
  display: flex;
  padding: 13.3px;
  justify-content: center;
  align-items: center;
  gap: 13.3px;
  border-radius: 10.6px;
  background: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}
.skills--section--card--content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.skills--section--card--icon > img {
  width: 50px;
  height: 50px;
}

.skills--section--title {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}
.skills--section--card--description {
  color: var(--heading-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 100%;
}

.tech--skills {
  overflow: hidden;
  position: relative;
}

.tech--skills--slider {
  display: flex;
  align-items: center;
  width: fit-content;
  max-height: 150px;
  animation: infiniteScroll 20s linear infinite;
}

.tech--skill-card {
  flex-shrink: 0;
  padding: 10px 20px;
  max-height: 200px;
}
/* Infinite scroll animation */
@keyframes infiniteScroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}


/* Skills Style Ends */

/* About Us */
.about--section {
  width: 100vw;
  display: flex;
  align-items: center;
}
.about--section--img > img {
  width: 70%;
  height: 70%;
  border-radius: 20%;
  max-width: 700px;
  padding-left: 0px;
}
/* About Us Ends */

/* My Portfolio Starts */
.portfolio--section {
  display: flex;
  padding: 112px 85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}
.portfolio--container-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.portfolio--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.3px;
}
.portfolio--section--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  width: auto;
}
.portfolio--section--img {
  border-radius: 8px;
  width: 100%;
}
.portfolio--section--img > img {
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.portfolio--section--card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 800px;
  gap: 0px;
  flex: 1 0 calc(33.333% - 28.4px);
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transform: translateY(200px);
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;
}

.portfolio--section--card.show {
  transform: translateY(0);
  opacity: 1;
}

.portfolio--section--card--content {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.portfolio--section--title {
  color: var(--heading-color);
}
.portfolio--link {
  text-decoration: none;
  display: flex;
  cursor: pointer;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
}
.portfolio--link:hover path {
  stroke: #3032d6;
}

.portfolio--tech-stack {
  padding: 10px 15px;
  background-color: #f9f9f9;
}

.tech-stack--title {
  font-size: 18px;
  margin-bottom: 10px;
}

.tech-stack--items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tech-stack--item {
  background-color: #e7e7e7;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.portfolio--section--card--content--icons {
  display: flex;
  gap: 16px;
}

/* Portfolio Ends */

/* Photography Starts*/
.photo--header {
  display: flex;
  padding-top: 10px;
  padding-bottom: 50px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}

.photo--container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  padding: 20px;
  width: auto;
  background-color: var(--bg-shade);
}

.photo--card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 calc(33.333% - 28.4px);
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
}

.photo--card > img {
  width: 100%;
}
/* Photography Ends */
.contact--section {
  display: flex;
  padding: 150px 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
}
.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat calc(100% - 20px); /* Better placement regardless of input width */
}
/*For IE*/
select::-ms-expand {
  display: none;
}
.checkbox--label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10.6px;
  flex: 1 0 0;
  justify-content: flex-start;
}
input[type="checkbox"] {
  width: 21px;
  height: 21px;
  border: 0.15em solid var(--heading-color);
  border-radius: 0.15em;
  transform: translatey(-0.075em);
}
/* Contact End */

/* Footer Start */
/* Footer Starts */
.footer--container {
  display: flex;
  width: 100vw;
  flex-direction: column;
  background: var(--bg-shade);
  align-items: center;
  align-self: stretch;
}
.footer--link--container {
  margin: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 18px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Responsive Screens */
@media only screen and (min-width: 1200px) {
  .navbar {
    max-width: 100vw !important;
  }

  .cta {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 1400px) {
  .hero--wrapper {
    display: flex;
    width: 100vw;
    flex-direction: column-reverse;
  }

  .hero--section--content--box {
    margin-top: 50px;
  }

  .hero--section--content--box {
    width: 70vw;
  }
}

@media only screen and (max-width: 1200px) {
  .portfolio--section {
    padding: 24px;
  }
  .btn-outline-primary {
    padding: 10px 26px;
    margin-right: 60px;
  }
  .hero--section {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }
  .footer--content {
    text-align: center;
  }
  .contact--form--container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }

  .portfolio--section--card {
    max-width: calc(50% - 30px);
    flex: 1 0 calc(50% - 20px);
  }

  .portfolio--section--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .tech--skills {
    gap: 20px;
  }

  .tech--skill-card,
  .tech--skill-card {
    max-width: 120px;
  }
}

/* Hamburger menu  */

.nav__hamburger {
  border: none;
  display: none;
  width: 2rem;
  height: 2rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 15px;
  right: 16px;
}

.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav__hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar--items {
    display: flex;
  }
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }
  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: center;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 30px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.45rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.45rem);
  }

  .portfolio--section--card {
    max-width: 100%;
    flex: 1 0 calc(50% - 50px);
  }

  .portfolio--section--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .hero--section--content--box {
    width: 90%;
  }

  h1 {
    font-size: 45px !important;
    line-height: 40px !important;
  }
  h2 {
    font-size: 40px !important;
    line-height: 35px !important;
  }
}

@media screen and (max-width: 500px) {
  .hero--section--img,
  .hero--section--img > img {
    margin-top: 24px;
    width: 300px;
    height: 300px;
  }

  .hero--section--content--box {
    margin-top: 75px;
  }

  h1 {
    font-size: 34px !important;
    line-height: 30px !important;
  }

  h2 {
    font-size: 30px !important;
    line-height: 25px !important;
  }

  .hero--section--content,
  .GIT {
    margin-left: 24px;
  }
  .tech--skill-card > img, .tech--skill-card {
    width: 50px;
    min-width: 100px;
    max-height: 100px;
  }

}
/* Responsive Screens Ends*/
